
import BaseTextSection from "@/components/base/BaseTextSection.vue";
import BaseSponsorScroll from "@/components/base/BaseSponsorScroll.vue";
import { partnerIcons } from "../../constants";
export default {
  components: {
    BaseTextSection,
    BaseSponsorScroll
  },
  data() {
    return {
      partnerIcons,
      row1: ['universal', 'sony', 'warner'],
      carouselRow: {
        en: ['symphonic', 'colonize', 'ingrooves', 'believe', 'merlin','tustream', 'vydia', 'sonosuite', 'dashgo', 'orchard', 'cinq', 'empire', 'tango', 'oplaai'],
        es: ['symphonic', 'colonize', 'ingrooves', 'believe', 'merlin','tustream', 'vydia', 'sonosuite', 'dashgo', 'orchard', 'cinq', 'empire', 'tango', 'oplaai'],
        id: ['symphonic', 'colonize', 'drm', 'vydia', 'gnp', 'aquarius', 'jkr', 'tango', 'empire', 'cinq', 'sonosuite', 'dashgo']
      }
    }
  },
  computed: {
    icons(){
      return this.carouselRow[this.$i18n.locale]
    }
  },
}
